.inputbox {
    position: relative;
    width: 250px;
  }
  
  .inputbox input {
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    box-shadow: none;
    border: none;
    color: #23242a;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;
  }
  
  .inputbox select {
    border: 1px solid white;
    position: relative;
    width: 100%;
    padding: 20px 10px 10px;
    background: transparent;
    outline: none;
    box-shadow: none;
    color: white;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
    z-index: 10;
    background-color: darkslategrey;
  }
  .inputbox span {
    position: absolute;
    left: 0;
    padding: 20px 10px 10px;
    font-size: 1em;
    color: #8f8f8f;
    letter-spacing: 00.05em;
    transition: 0.5s;
    pointer-events: none;
  }
  
  .inputbox input:valid ~span,
  .inputbox input:focus ~span {
    color: #45f3ff;
    transform: translateX(-10px) translateY(-38px);
    font-size: 0,75em;
  }
  
  .inputbox i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #ffffff;
    border-radius: 4px;
    transition: 0.5s;
    pointer-events: none;
    z-index: 9;
  }
  
  .inputbox input:valid ~i,
  .inputbox input:focus ~i {
    height: 44px;
  }
  @media only screen and (max-width: 478px) {
    .inputbox{
      width: 70w;
      margin:20px 0; 
    }
    .inputbox input{
      width: 70vw;
    }
  } 